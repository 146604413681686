import {PayloadAction, createSlice} from "@reduxjs/toolkit"

export const SessionExpiredSlice: any = createSlice({
    name: "sessionExpired",
    initialState: {enableSessionExpired: false, error_loading: null} as {
        enableSessionExpired: boolean
        error_loading: null | boolean
    },
    reducers: {
        setSessionExpiredLoading: (state, {payload: {error_loading}}: PayloadAction<{error_loading: boolean}>) => {
            state.error_loading = error_loading
        },
        setSessionExpiredFunc: (
            state,
            {payload: {enableSessionExpired}}: PayloadAction<{enableSessionExpired: boolean}>
        ) => {
            state.error_loading = true
            state.enableSessionExpired = enableSessionExpired
        },
    },
})

export const {setSessionExpiredLoading, setSessionExpiredFunc} = SessionExpiredSlice.actions

export default SessionExpiredSlice.reducer
