import {PayloadAction, createSlice} from "@reduxjs/toolkit"

export const Error401Slice: any = createSlice({
    name: "error401",
    initialState: {enable401: false, error_loading: null} as {
        enable401: boolean
        error_loading: null | boolean
    },
    reducers: {
        setError404Loading: (state, {payload: {error_loading}}: PayloadAction<{error_loading: boolean}>) => {
            state.error_loading = error_loading
        },
        setError401Func: (state, {payload: {enable401}}: PayloadAction<{enable401: boolean}>) => {
            state.error_loading = true
            state.enable401 = enable401
        },
    },
})

export const {setError404Loading, setError401Func} = Error401Slice.actions

export default Error401Slice.reducer
